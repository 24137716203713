import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../components/Loader';
import '../styles/home.scss';

const HomeLazy = Loadable({
  loader: () => import('../containers/Home'),
  loading: () => <Loader />,
});

const Index = () => {
  return (
    <>
      <HomeLazy />
    </>
  );
};
export default Index;
