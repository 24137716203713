import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import Logo from '../Logo';

import './style.scss';

const Loader = () => {
  useEffect(() => {
    const counter = document.querySelector('.loader-counter-content');
    const loader = document.querySelector('.loader');

    gsap
      .timeline()
      .from(counter, {
        textContent: 0,
        duration: 0.75,
        ease: 'circ.easeOut',
        snap: { textContent: 1 },
        stagger: 1,
      })
      .to(loader, { duration: 0.4, opacity: 0 });
  }, []);
  return (
    <section className="loader absolute">
      <Logo />
      <p className="loader-counter">
        <span className="loader-counter-content">100</span>%
      </p>
    </section>
  );
};

export default Loader;
